/* 首页头图加载 */
.pl-container {
    width: 100%;
    height: 100%;
    position: relative;  /* 一图流这里改fixed */
    /* 一图流这里加z-index: -2; */
    overflow: hidden;
    will-change: transform; /* 添加性能优化 */
    /* blur-to-clear模糊动画2s */
    animation: blur-to-clear 2s cubic-bezier(.62,.21,.25,1) 0s 1 normal backwards running, scale 1.5s cubic-bezier(.62,.21,.25,1) 0s 1 both;
}
.pl-img {
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 1s;
}

@keyframes blur-to-clear {
    0% {
        filter: blur(50px);
        opacity: 1;
    }
    100% {
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes scale {
    0% {
        transform: scale(1.5) translateZ(0);
        opacity: 0;
    }
    to {
        transform: scale(1) translateZ(0);
        opacity: 1;
    }
}

.pl-visible {
    opacity: 1;
}

.pl-blur {
    /* 小图锯齿多，增加高斯模糊 */
    filter: blur(50px);
}

#web_bg {
    background-image: url(/img/bg1.webp);
    background-position: center;
    background-repeat: no-repeat;
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    -webkit-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
}